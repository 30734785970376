import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Footer from './Footer';

export default function CategoryPage() {
  const { category } = useParams(); 
  const [images, setImages] = useState([]);

  useEffect(() => {
   
    fetch('/data/data.json')
      .then(response => response.json())
      .then(data => {
       
        const filteredImages = data.filter(item => item.title === category);
        setImages(filteredImages);
      });
  }, [category]);

  return (
    <>
    <div>
      <div 
        className='Content py-24 px-4 md:px-8 lg:px-16 xl:px-24'
        style={{
          marginLeft: 'clamp(1rem, 9vw, 10rem)',
          marginRight: 'clamp(1rem, 9vw, 10rem)',
        }}
      >
        <h1 className='text-center text-3xl md:text-4xl lg:text-5xl xl:text-6xl'>{category}</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
          {images.map((image, index) => (
            <div key={index} className="relative">
              <img 
                src={image.image} 
                alt={category} 
                className="object-cover w-[27rem] h-[45rem] max-w-full max-h-full"
                style={{
                  width: 'clamp(20rem, 30vw, 27rem)',
                  height: 'clamp(25rem, 35vw, 45rem)'
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
}
