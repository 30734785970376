import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Footer from './Footer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ContactPage() {
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm(formState);
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length === 0) {
      // EmailJS integration to send the form
      emailjs.send('service_hckmwcu', 'template_2tqt1in', formState, 'vzLx1JB2iqV74sXWi')
        .then((result) => {
          console.log('Email successfully sent!', result.status, result.text);
          // Display success toast notification
          toast.success('We have received your message and will get back to you as soon as possible.');
        }, (error) => {
          console.log('Failed to send email.', error);
          // Display error toast notification
          toast.error('Please fill out the information completely.');
        });
    } else {
      // Display error toast notification for form validation errors
      toast.error('Please fill out the information completely.');
    }
  };
  const validateForm = (state) => {
    const newErrors = {};
    if (!state.firstName) newErrors.firstName = 'First name is required';
    if (!state.lastName) newErrors.lastName = 'Last name is required';
    if (!state.email) newErrors.email = 'Email is required';
    if (!state.message) newErrors.message = 'Message is required';
    return newErrors;
  };

  
  return (
    <>
      <div className="container mx-auto py-12 px-4 lg:px-16">
        {/* Showroom Section */}
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-16">
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <h2 className="text-4xl font-serif text-[#86A04C] mb-6">Showroom</h2>
            <div className="text-lg text-[#4A6A8A]">
              <p>
                <strong>Address:</strong> <br />
                2413 Pacific Coast Hwy. #302, <br />
                Lomita, CA 90717
              </p>
              <p className="mt-4">
                <strong>Phone:</strong> +1(310) 982-6404
              </p>
              <p className="mt-4">
                <strong>Email:</strong>{' '}
                <a href="mailto:info@segnatile.com" className="text-[#4A6A8A] hover:underline">
                  info@segnatile.com
                </a>
              </p>
              <p className="mt-4">
                <strong>Hours:</strong> 7 days 10 am to 6 pm <br />
              </p>
            </div>
            <div className="mt-8">
              <button className="px-6 py-3 bg-[#86A04C] text-white font-bold rounded hover:bg-[#4A6A8A] transition duration-300">
                REQUEST AN APPOINTMENT
              </button>
            </div>
          </div>

          <div className="lg:w-1/2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.8609167410496!2d-118.32832522383117!3d33.79008963152645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd4a42df7cdccd%3A0x7a792973ffd63af5!2s2413%20Pacific%20Coast%20Hwy%2C%20Lomita%2C%20CA%2090717%2C%20Amerika%20Birle%C5%9Fik%20Devletleri!5e0!3m2!1sen!2sus!4v1723713206849!5m2!1sen!2sus&language=en&hl=en"
              width="100%"
              height="450"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        {/* Contact Us Section */}
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-start bg-[#EFECE2] p-12 rounded-lg">
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <h2 className="text-4xl font-serif text-[#86A04C] mb-6">Contact Us</h2>
            <p className="text-lg text-[#4A6A8A]">
              No project is too big or too small. Our exceptional design team will help you curate your vision into reality. 
              We are here to help, so please reach out and we look forward to seeing you!
            </p>
          </div>

          <div className="lg:w-1/2">
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block text-sm font-bold mb-2 text-[#4A6A8A]" htmlFor="firstName">
                    First Name <span className="text-red-500">*</span>
                  </label>
                  <input 
                    type="text" 
                    id="firstName"
                    name="firstName"
                    value={formState.firstName}
                    onChange={handleChange}
                    className={`w-full px-4 py-2 border ${errors.firstName ? 'border-red-500' : 'border-[#4A6A8A]'} rounded focus:outline-none focus:border-[#86A04C]`} 
                  />
                  {errors.firstName && <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>}
                </div>

                <div>
                  <label className="block text-sm font-bold mb-2 text-[#4A6A8A]" htmlFor="lastName">
                    Last Name <span className="text-red-500">*</span>
                  </label>
                  <input 
                    type="text" 
                    id="lastName"
                    name="lastName"
                    value={formState.lastName}
                    onChange={handleChange}
                    className={`w-full px-4 py-2 border ${errors.lastName ? 'border-red-500' : 'border-[#4A6A8A]'} rounded focus:outline-none focus:border-[#86A04C]`} 
                  />
                  {errors.lastName && <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>}
                </div>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-bold mb-2 text-[#4A6A8A]" htmlFor="email">
                  Email <span className="text-red-500">*</span>
                </label>
                <input 
                  type="email" 
                  id="email"
                  name="email"
                  value={formState.email}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border ${errors.email ? 'border-red-500' : 'border-[#4A6A8A]'} rounded focus:outline-none focus:border-[#86A04C]`} 
                />
                {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
              </div>

              <div className="mb-4">
                <label className="block text-sm font-bold mb-2 text-[#4A6A8A]" htmlFor="message">
                  Message <span className="text-red-500">*</span>
                </label>
                <textarea 
                  id="message"
                  name="message"
                  value={formState.message}
                  onChange={handleChange}
                  className={`w-full px-4 py-2 border ${errors.message ? 'border-red-500' : 'border-[#4A6A8A]'} rounded focus:outline-none focus:border-[#86A04C]`} 
                  rows="4"
                ></textarea>
                {errors.message && <p className="text-red-500 text-xs mt-1">{errors.message}</p>}
              </div>

              <button 
                type="submit" 
                className="w-full bg-[#86A04C] text-white font-bold py-2 px-4 rounded hover:bg-[#4A6A8A] transition duration-300"
              >
                SUBMIT
              </button>
              <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
