import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header'; 
import PortfolioPage from './components/PortfolioPage';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import ContactPage from './components/ContactPage';
import CategoryPage from './components/CategoryPage'; 
import ScrollToTop from './components/ScrollToTop'; 
import RequestSamples from './components/RequestSamples';
import AllPortfolio from './components/AllPortfolio';


function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/:category" element={<CategoryPage />} />  
        <Route path="/samples" element={<RequestSamples />} />
        <Route path="/allproducts" element={<AllPortfolio />} />
      </Routes>
      
    </Router>
  );
}

export default App;
