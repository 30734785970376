import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';

export default function Header() {
    
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    window.location.href = '/';  // Redirect to the homepage
  };

  const getLinkClass = (path) => {
    return location.pathname === path ? 'underline' : '';
  };

  return (
    <header className="header shadow-inner pt-4 pb-4 px-4 md:px-16 bg-[#f0ece2] flex items-center justify-between">
       
      <nav className="header-nav hidden md:flex gap-8">
        <Link to="/" className={`text-[#2c5a71] font-light ${getLinkClass('/')}`}>Home</Link>
        <Link to="/about" className={`text-[#2c5a71] font-light ${getLinkClass('/about')}`}>About</Link>
        <Link to="/portfolio" className={`text-[#2c5a71] font-light ${getLinkClass('/portfolio')}`}>Portfolio</Link>
        
      </nav>

      <div className="header-logo">
        <Link to="/">
          <img 
            src="/images/Logo.svg" 
            alt="SegnaTile & Stone Studio" 
            className="block w-36 md:w-72"
          />
        </Link>
      </div>
      <nav className='header-nav hidden md:flex'>
      <div className='flex flex-row gap-8'>
      <Link to="/contact" className={`text-[#2c5a71] font-light ${getLinkClass('/contact')}`}>Contact</Link>
        <Link to="/samples" className={`text-[#2c5a71] font-light ${getLinkClass('/samples')}`}>Request Samples </Link>
        </div>
        </nav>
     
      {/* Mobile Menu Icon */}
      <div className="md:hidden">
        <button onClick={toggleMenu} className="text-[#2c5a71]">
          <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} size="2xl" />
        </button>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-[#f4f4f4] z-50 flex flex-col items-center justify-center space-y-8">
          <button onClick={closeMenu} className="absolute top-4 right-4 text-[#2c5a71]">
            <FontAwesomeIcon icon={faTimes} size="2xl" />
          </button>
          <Link to="/" className={`text-[#2c5a71] text-2xl ${getLinkClass('/')}`} onClick={toggleMenu}>Home</Link>
          <Link to="/about" className={`text-[#2c5a71] text-2xl ${getLinkClass('/about')}`} onClick={toggleMenu}>About</Link>
          <Link to="/portfolio" className={`text-[#2c5a71] text-2xl ${getLinkClass('/portfolio')}`} onClick={toggleMenu}>Portfolio</Link>
          <Link to="/contact" className={`text-[#2c5a71] text-2xl ${getLinkClass('/contact')}`} onClick={toggleMenu}>Contact</Link>
          <Link to="/samples" className={`text-[#2c5a71] text-2xl ${getLinkClass('/samples')}`} onClick={toggleMenu}>Request Samples</Link>
          <a 
            href="https://www.instagram.com/segnatile" 
            target="_blank" 
            rel="noopener noreferrer" 
            aria-label="Instagram" 
            className="text-[#2c5a71]"
          >
            <FontAwesomeIcon icon={faInstagram} size="2xl" />
          </a>
        </div>
      )}
    </header>
  );
}
