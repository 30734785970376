import React, { useRef } from 'react';

import 'swiper/css';

import { Link } from 'react-router-dom';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import "@fontsource/montserrat";
import "@fontsource/playfair-display"; 
import CustomSlider from './CustomSlider';

export default function HomePage() {
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  const handleClick = () => {
    navigate('/portfolio');
  };

  return (
    <>
      {/* Enhanced Mosaic Section with Welcome Message and Updated Colors */}
      <div className="container shadow-inner mx-auto flex flex-col md:flex-row items-center my-16 px-6 py-8 lg:px-16 bg-[#f0ece2]">
        <div className="md:w-1/2 lg:w-[40%] my-14">
          <img
            src="/images/mosaic.jpg"
            className="w-full h-[20rem] lg:h-[30rem] object-cover rounded-lg shadow-lg"
            alt="Mosaics"
          />
        </div>
        <div className="md:w-1/2 lg:w-[60%] mt-8 md:mt-0 md:pl-10">
          <p className="text-[#2c5a71] text-[1.5rem] md:text-[2rem] lg:text-[2.5rem] font-playfair font-light leading-relaxed">
            "We are the mosaics. Pieces of light, love, history, stars… Glued together with magic and music and words."
          </p>
          <p className="mt-4 text-[#2c5a71] font-montserrat text-lg">
            Welcome to the world of Segna Tile, where each creation is a testament to innovation, craftsmanship, and passion. We invite you to explore our unique designs, crafted to bring beauty and harmony into every space. Our commitment is to stay at the forefront of trends, crafting beautiful and unique spaces tailored specifically for you with team of innovative design experts who are dedicated to turning your vision into reality.
            <br />
            <h1 className='pt-4 text-xl text-[#2c5a71]'>- Let’s get started! -</h1>
          </p>
          <Link to="/contact">
            <button className='px-5 py-4 mt-4 text-[#ffffff] font-bold bg-[#65883a] border-solid border-black hover:bg-[#2c5a71] hover:text-[#ffffff]'>
              REQUEST AN APPOINTMENT
            </button>
          </Link>
        </div>
      </div>

      <div className='flex flex-col bg-[#EFECE2] shadow-inner  justify-center items-center gap-8 py-16 px-[20px]'>
        <h1 className='font-serif text-[#2c5a71] text-[4rem] font-light text-center'><CustomSlider/></h1>
        <p className='font-sans text-[#2c5a71] text-[1.5rem] font-light leading-relaxed text-center'>
        </p>
      </div>


      {/* Fixed-size Instagram Grid Section */}
      <div className="py-16 mx-auto px-4 md:px-8 lg:px-[30rem] max-w-[1920px] bg-[#ffffff]">
        <div className="flex justify-between items-center mb-8">
          <div>
            <h2 className="text-[2rem] md:text-[3rem] font-bold text-[#4A6A8A]">Let's Be Friends!</h2>
            <p className="text-[1rem] md:text-[1.5rem] text-[#86A04C]">
              <a href='https://www.instagram.com/segnatile'>@segnatile</a>
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-[1rem]">
          {[
            { src: '/images/insta/ARC-Biotech-24.webp', link: 'https://www.instagram.com/segnatile' },
            { src: '/images/insta/ARC-Biotech-36.webp', link: 'https://www.instagram.com/segnatile' },
            { src: '/images/insta/ARC-Biotech-39.webp', link: 'https://www.instagram.com/segnatile' },
            { src: '/images/insta/compatta-01_openspace_giorno_mb01.webp', link: 'https://www.instagram.com/segnatile' },
            { src: '/images/insta/CRE-essential-mood-28.webp', link: 'https://www.instagram.com/segnatile' },
            { src: '/images/insta/CRE-essential-mood-44.webp', link: 'https://www.instagram.com/segnatile' },
            { src: '/images/insta/CRE-essential-mood-51.webp', link: 'https://www.instagram.com/segnatile' },
            { src: '/images/insta/essential-mood-riv-color_02_pav-cool-03.webp', link: 'https://www.instagram.com/segnatile' },
            { src: '/images/insta/essential-mood-riv-cool-01_pav-color-03.webp', link: 'https://www.instagram.com/segnatile' },
            { src: '/images/insta/LUX-Eccentric-luxe-02.webp', link: 'https://www.instagram.com/segnatile' },
            { src: '/images/insta/LUX-Eccentric-luxe-22.webp', link: 'https://www.instagram.com/segnatile' },
            { src: '/images/insta/LUX-Eccentric-luxe-28-1.webp', link: 'https://www.instagram.com/segnatile' }
          ].map((item, index) => (
            <a href={item.link} key={index} className="block w-full h-[150px] sm:h-[200px] md:h-[250px] lg:h-[150px]">
              <img src={item.src} alt={`Instagram image ${index + 1}`} className="w-full h-full object-cover" />
            </a>
          ))}
        </div>
        <div className="flex justify-center mt-8">
          <a
            href="https://www.instagram.com/segnatile"
            target="_blank"
            rel="noopener noreferrer"
            className='px-5 py-4 text-[#ffffff] font-bold bg-[#65883a] border-solid border-black hover:bg-[#2c5a71] hover:text-[#ffffff] text-center'
          >
            FOLLOW US
          </a>
        </div>
    

      
        
      </div>
        

      <Footer />
    </>
  );
}
