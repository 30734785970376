import React from 'react';
import Footer from './Footer';
import { Link } from 'react-router-dom';
export default function AboutPage() {
  return (
    <>
      {/* About Us Section */}
      <div className="flex justify-center items-center py-36 bg-[#ffffff]">
        <div className="w-full md:w-2/4 px-4 flex flex-col items-center text-start">
          <h1 className="text-3xl font-bold text-[#86A04C] mb-4">About Us</h1>
          <p className="text-[#4A6A8A]">
            Segna Tile's expert design team turns your concepts into reality. We specialize in bringing your unique colors, textures, and patterns to life. We create spaces that reflect your personality, ensuring they are beautifully designed and unforgettable. Our mission is to harmonize your interior and exterior with cohesive flow, intentional style, and timeless design, so you can enjoy them with family and friends for a lifetime.
          </p>
        </div>
      </div>
      
      {/* Materials and Client Services Section */}
      <div className="AboutMaterials bg-[#f0ece2] py-36 flex flex-col items-center gap-11">
        <div className="w-full max-w-2xl px-4 flex flex-col items-start text-start mb-8">
          <h1 className="text-3xl font-bold text-[#86A04C] mb-4">Materials</h1>
          <p className="text-[#4A6A8A]">
            At Segna Tile, we partner with over 150 vendors and artisans to offer a curated selection of unique ceramics, natural stones, mosaics, slabs, pavers, and specialized exterior materials.
          </p>
        </div>
        <div className="w-full max-w-2xl px-4 flex flex-col items-start text-start mb-8">
          <h1 className="text-3xl font-bold text-[#86A04C] mb-4">Client Services</h1>
          <p className="text-[#4A6A8A]">
            Segna Tile is a proud, locally owned business with an exceptional client services team. From assisting with design, sourcing your materials, and ensuring smooth delivery to our local warehouse, we are dedicated to making your ordering experience effortless. We look forward to the chance to collaborate with you soon.
          </p>
        </div>
        <div className="w-full max-w-2xl px-4 flex flex-col items-start text-start mb-8">
          <h1 className="text-3xl font-bold text-[#86A04C] mb-4">Let’s Get Together</h1>
          <p className="text-[#4A6A8A]">
            At Segna Tile, we treat every project with care, which is why we work by appointment only. We dedicate our time to you because no project is too large or too small. Whether it's a full-scale design/build or a small kitchen remodel, we look forward to meeting you and turning your vision into reality.
          </p>
        </div>
        <Link to="/contact">
        <div className="w-full max-w-2xl px-4 flex flex-col items-start text-center mb-8">
          <button className='px-6 py-4 bg-[#86A04C] text-[#ffffff] font-semibold hover:bg-[#4A6A8A] hover:text-[#ffffff]'>
            REQUEST AN APPOINTMENT
          </button>
        </div>
        </Link>
      </div>

      {/* Instagram Grid Section */}
      <div className="py-16 mx-auto px-4 md:px-8 lg:px-[30rem] max-w-[1920px] bg-[#ffffff]">
        <div className="flex justify-between items-center mb-8">
          <div>
            <h2 className="text-[2rem] md:text-[3rem] font-bold text-[#4A6A8A]">Let's Be Friends!</h2>
            <p className="text-[1rem] md:text-[1.5rem] text-[#86A04C]">
              <a href='https://www.instagram.com/segnatile'>@segnatile</a>
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-[1rem]">
          {[
           { src: '/images/insta/ARC-Biotech-24.webp', link: 'https://www.instagram.com/segnatile' },
           { src: '/images/insta/ARC-Biotech-36.webp', link: 'https://www.instagram.com/segnatile' },
           { src: '/images/insta/ARC-Biotech-39.webp', link: 'https://www.instagram.com/segnatile' },
           { src: '/images/insta/compatta-01_openspace_giorno_mb01.webp', link: 'https://www.instagram.com/segnatile' },
           { src: '/images/insta/CRE-essential-mood-28.webp', link: 'https://www.instagram.com/segnatile' },
           { src: '/images/insta/CRE-essential-mood-44.webp', link: 'https://www.instagram.com/segnatile' },
           { src: '/images/insta/CRE-essential-mood-51.webp', link: 'https://www.instagram.com/segnatile' },
           { src: '/images/insta/essential-mood-riv-color_02_pav-cool-03.webp', link: 'https://www.instagram.com/segnatile' },
           { src: '/images/insta/essential-mood-riv-cool-01_pav-color-03.webp', link: 'https://www.instagram.com/segnatile' },
           { src: '/images/insta/LUX-Eccentric-luxe-02.webp', link: 'https://www.instagram.com/segnatile' },
           { src: '/images/insta/LUX-Eccentric-luxe-22.webp', link: 'https://www.instagram.com/segnatile' },
           { src: '/images/insta/LUX-Eccentric-luxe-28-1.webp', link: 'https://www.instagram.com/segnatile' }
          ].map((item, index) => (
            <a href={item.link} key={index} className="block w-full h-[150px] sm:h-[200px] md:h-[250px] lg:h-[150px]">
              <img src={item.src} alt={`Instagram image ${index + 1}`} className="w-full h-full object-cover" />
            </a>
          ))}
        </div>
        <div className="flex justify-center mt-8">
          <a
            href="https://www.instagram.com/segnatile"
            target="_blank"
            rel="noopener noreferrer"
            className='px-5 py-4 text-[#ffffff] font-bold bg-[#65883a] border-solid border-black hover:bg-[#2c5a71] hover:text-[#ffffff] text-center'
          >
            FOLLOW US
          </a>
        </div>
    

      
        
      </div>

      <Footer />
    </>
  );
}
