import React from 'react';
import "@fontsource/montserrat"; 

export default function Footer() {
  return (
    <div className='Footer py-24 shadow-inner mt-14 bg-[#f0ece2] flex flex-col md:flex-row justify-evenly items-center text-center'>
      <div className='Foot1 gap-9 flex flex-col items-center mb-8 md:mb-0'>
        <h1 className='text-lg text-[#2c5a71] font-montserrat'>Location</h1>
        <p className='text-base text-[#2c5a71] font-montserrat'>
          Atrium Design Center <br/> 2413 Pacific Coast Hwy. #302,<br />
          Lomita, CA 90717
        </p>
      </div>
      <div className='Foot2 gap-9 flex flex-col items-center mb-8 md:mb-0'>
        <h1 className='text-lg font-light text-[#2c5a71] font-montserrat'>Hours</h1>
        <p className='text-base text-[#2c5a71] font-montserrat'>
          Store hrs. 7 days a week <br />
          10 am to 6 pm
        </p>
      </div>
      <div className='Foot3 gap-9 flex flex-col items-center'>
        <h1 className='text-lg text-[#2c5a71] font-light font-montserrat'>Contact</h1>
        <p className='text-base text-[#2c5a71] font-montserrat'>
          info@segnatile.com<br />
          +1(310) 982-6404
        </p>
      </div>
      
    </div>
  )
}
