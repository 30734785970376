import React from 'react'
import Footer from './Footer'
import { Link } from 'react-router-dom'

export default function RequestSamples() {
  return (
    <>
      <div>
        <img className='w-screen my-16 h-[30rem]' src='/images/request-samples-hero1.webp' alt='Request Samples'></img>
      </div>
      <div className='flex flex-col justify-center items-center my-20 gap-10'>
        <h1 className='text-[#272727] font-playfair font-normal text-5xl text-center'>
          Request samples to experience <br /> the quality of our products.
        </h1>
        <p className='text-center text-xl font-light'>
          Our high-quality 4" x 4" samples accurately reflect the color and finish of all tile sizes within each <br />collection, ensuring you receive a true representation of our premium products.
        </p>
        <Link to="/contact">
          <button className='px-6 py-4 bg-[#86A04C] text-[#ffffff] font-semibold hover:bg-[#4A6A8A] hover:text-[#ffffff]'>
           Order Samples
          </button>
        </Link>
      </div>
      <Footer/>
    </>
  )
}
