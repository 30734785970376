import React, { useState, useEffect } from 'react';

const images = [
    '/images/slider/ARC-Biotech-32.webp',
    '/images/slider/ARC-Biotech-40.webp',
    '/images/slider/ARC-Coretech-45.webp',
    '/images/slider/compatta-01_openspace_giorno_dett2_01.webp',
    '/images/slider/compatta-02_camera_da_letto_02.webp',
    '/images/slider/compatta-02_camera_da_letto_dett2_02.webp',
    '/images/slider/compatta-03_hall_albergo_04.webp',
    '/images/slider/compatta-04_bagno_suite_03.webp',
    '/images/slider/compatta-04_bagno_suite_dett2_02.webp',
    '/images/slider/compatta-06_negozio_dett2_03.webp',
    '/images/slider/CRE-essential-mood-37.webp',
    '/images/slider/CRE-essential-mood-47.webp',
    '/images/slider/CRE-essential-mood-48.webp',
    '/images/slider/CRE-essential-mood-49.webp',
    '/images/slider/CRE-essential-mood-50.webp',
    '/images/slider/CRE-essential-mood-55.webp',
    '/images/slider/CRE-essential-mood-58.webp',
    '/images/slider/CRE-essential-mood-59.webp',
    '/images/slider/CRE-essential-mood-67.webp',
    '/images/slider/eccentric-luxe-venato-154.webp',
    '/images/slider/LUX-Eccentric-luxe-03.webp',
    '/images/slider/LUX-Eccentric-luxe-06.webp',
    '/images/slider/LUX-Eccentric-luxe-09.webp',
    '/images/slider/LUX-Eccentric-luxe-11.webp',
    '/images/slider/LUX-Eccentric-luxe-14.webp',
    '/images/slider/LUX-Eccentric-luxe-15.webp',
    '/images/slider/LUX-Eccentric-luxe-16.webp',
    '/images/slider/LUX-Eccentric-luxe-21.webp',
    '/images/slider/LUX-Eccentric-luxe-23.webp',
    '/images/slider/LUX-Eccentric-luxe-24.webp',
    '/images/slider/LUX-Eccentric-luxe-25.webp',
    '/images/slider/LUX-Eccentric-luxe-26.webp',
    '/images/slider/LUX-Eccentric-luxe-29.webp',
    '/images/slider/LUX-mystic-luxe-01.webp'
];

const CustomSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 2000); // Change image every 2 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    return (
        <div className="relative w-full h-80 md:h-[40rem] overflow-hidden bg-[#EFECE2]">
            <img
                src={images[currentIndex]}
                alt={`Slide ${currentIndex}`}
                className="object-cover w-full h-full transition-opacity duration-1000"
                style={{ opacity: 1 }} // Opacity setting
            />
        </div>
    );
};

export default CustomSlider;
