import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import "@fontsource/montserrat";

export default function PortfolioPage() {
  const swiperRef = useRef(null);

  return (
    <>
      <div className='PortText flex justify-center my-8 '>
      
      </div>
      
      {/* Updated Slider Section */}
      <div className='ViewPortfolio shadow-inner py-20 relative bg-[#EFECE2]'>
        <h1 className='font-serif text-[#2c5a71] py-12 text-3xl font-light text-center mb-8'>Make it stand out.</h1>
        <div className="max-w-6xl mx-auto">  {/* Setting max-width to ensure consistency */}
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            onSwiper={(swiper) => (swiperRef.current = swiper)} // Correctly assigning swiper instance to ref
            breakpoints={{
              640: {
                slidesPerView: 1,  // Ensure only 1 slide on small screens
                spaceBetween: 20,  // Adjusting space for smaller screens
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,  // Adjusting space for medium screens
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 60,  // Adjusting space for large screens
              },
            }}
            className="SliderPortf"
          >
            {[{
              src: '/images/kitchen/FLT-MNT-216_installationRender.webp',
              title: 'Kitchen'
            }, {
              src: '/images/bath/Banyo34.webp',
              title: 'Bath'
            }, {
              src: '/images/interior/Icmekan4.webp',
              title: 'Interior'
            },
            {
              src: '/images/fireplace/Somine4.webp',
              title: 'Fireplace'
            },
            {
              src: '/images/Zanzibar+-+Met+(2)+copy.jpg',
              title: 'Outdoor & Pool'
            },
          
            ].map((item, index) => (
              <SwiperSlide key={index} className="Port flex flex-col items-center justify-center">
                <Link to={`/${item.title.replace(/\s+/g, '')}`}>
                  <div className="w-full h-[30rem] md:w-[18rem] lg:w-[23rem] md:h-[32rem] lg:h-[34rem]">
                    <img src={item.src} alt={item.title} className="object-cover w-full h-full transition-transform duration-1000 hover:scale-110" />
                  </div>
                  <p className="text-center font-light font-montserrat text-2xl mt-4 text-[#2c5a71]'">{item.title}</p>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>  {/* End of max-width constraint div */}
        <div className="flex justify-center py-8 mt-8">
          <Link to="/allproducts">
            <button className='px-5 py-4 text-[#ffffff] font-bold bg-[#65883a] border-solid border-black hover:bg-[#2c5a71] hover:text-[#ffffff]'>
              VIEW PORTFOLIO
            </button>
          </Link>
        </div>

        {/* Responsive Navigation Buttons */}
        <button
          className="absolute top-1/2 left-2 md:left-64 transform -translate-y-1/2 text-[#ffffff] text-2xl z-10 w-12 h-12 bg-[#65883a] rounded-full flex items-center justify-center shadow-lg hover:bg-[#2c5a71] hover:text-[#ffffff]"
          onClick={() => swiperRef.current?.slidePrev()} // Navigate to the previous slide
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <button
          className="absolute top-1/2 right-2 md:right-64 transform -translate-y-1/2 text-[#ffffff] text-2xl z-10 w-12 h-12 bg-[#65883a] rounded-full flex items-center justify-center shadow-lg hover:bg-[#2c5a71] hover:text-[#ffffff]"
          onClick={() => swiperRef.current?.slideNext()} // Navigate to the next slide
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>

      <hr></hr>
      <Footer />
    </>
  )
}
